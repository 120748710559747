export const checkValidations = (state) => {
  let newState = {};
  let noTrim = ['undefined', 'object', 'boolean', 'number'];
  let error = '';
  for (let [key, value] of Object.entries(state)) {
    if (!noTrim.includes(typeof value)) {
      value = value.trim();
      if (value === '') {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        error += `${key},`;
      }
      newState[key] = value;
    } else {
      newState[key] = value;
    }
  }
  if (error !== '') {
    error = error.substring(0, error.length - 1);
    error += ' is required!';
  } else {
    error = false;
  }
  return { data: newState, error: error };
};

export const checkError = (errors) => {
  let err = '<ul>';
  let disabled = false;
  for (var key of Object.keys(errors)) {
    if (errors[key].length > 0) {
      err += `<li>${key} ${errors[key]}<li>`;
      disabled = true;
    }
  }
  err += '</ul>';
  return { error: err, disabled };
};

// This function formats all prices for the Pharmacy Panel as: $123.00 CAD
export const Format_Number = (value) => {
  return `$${(Number(value || 0) / 100).toFixed(2)}`;
};

// Old Format_Number function
/*
export const Format_Number = (value) => {
  return `CAD ${Number(value || 0).toFixed(2)}`;
};
*/
export const provinceTimezones = {
  // Ontario
  ON: "America/Toronto",
  On: "America/Toronto",
  Ontario: "America/Toronto",

  // Quebec
  QC: "America/Toronto",
  Qc: "America/Toronto",
  Quebec: "America/Toronto",

  // British Columbia
  BC: "America/Vancouver",
  Bc: "America/Vancouver",
  "British Columbia": "America/Vancouver",

  // Alberta
  AB: "America/Edmonton",
  Ab: "America/Edmonton",
  Alberta: "America/Edmonton",

  // Saskatchewan
  SK: "America/Regina",
  Sk: "America/Regina",
  Saskatchewan: "America/Regina",

  // Manitoba
  MB: "America/Winnipeg",
  Mb: "America/Winnipeg",
  Manitoba: "America/Winnipeg",

  // Newfoundland
  NL: "America/St_Johns",
  Nl: "America/St_Johns",
  Newfoundland: "America/St_Johns",

  // Nova Scotia
  NS: "America/Halifax",
  Ns: "America/Halifax",
  "Nova Scotia": "America/Halifax",

  // Prince Edward Island
  PE: "America/Halifax",
  Pe: "America/Halifax",
  "Prince Edward Island": "America/Halifax",

  // New Brunswick
  NB: "America/Halifax",
  Nb: "America/Halifax",
  "New Brunswick": "America/Halifax",

  // Northwest Territories
  NT: "America/Yellowknife",
  Nt: "America/Yellowknife",
  "Northwest Territories": "America/Yellowknife",

  // Yukon
  YT: "America/Whitehorse",
  Yt: "America/Whitehorse",
  Yukon: "America/Whitehorse",

  // Nunavut
  NU: "America/Iqaluit",
  Nu: "America/Iqaluit",
  Nunavut: "America/Iqaluit",
};

export function getTimezone(province) {
  const normalizedProvince =
    province.length > 2 ? province.charAt(0).toUpperCase() + province.slice(1).toLowerCase() : province;

  return provinceTimezones[normalizedProvince] || "UTC";
}
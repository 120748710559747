import React, { Component, useState, Fragment } from 'react';
import moment from 'moment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Cookies from 'js-cookie';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Format_Number, Loading } from '../index';
import Status from '../Status';

import { Link, NavLink, withRouter } from 'react-router-dom';
import purolatorIcon from '../../assets/images/purolator.png';
import uberIcon from '../../assets/images/uber.png';
import { getPriceWithoutTax, getPriceWithTax } from '../DeliveryOrderReview/ReviewModal.js';
import { convertUberUndeliverableReasonMessage } from '../../util/functions.js';
import { provinceTaxes, roundToTwo } from '../../constants/taxes.js';

class DataTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Table>{this.props.children}</Table>;
  }
}

const DataTableHead = (props) => {
  const { columns, sort, orderBy, style } = props;
  const updateProps = (data) => {
    if (props.sort) props.sort(data);
  };
  let rightColums = [];
  return (
    <TableHead>
      <TableRow>
        {columns.length > 0 &&
          columns.map((column) => {
            return (
              <TableCell
                key={column.id}
                style={style}
                align={!rightColums.includes(column.id) ? 'left' : 'right'}
                padding={column.disablePadding ? 'none' : 'default'}
                onClick={() =>
                  updateProps({
                    key: column.id,
                    order: parseInt(column.order) === 1 ? -1 : 1,
                  })
                }
              >
                {column.label}
                {orderBy === column.id && orderBy.toLowerCase() !== 'action' && (
                  <TableSortLabel active={true} direction={column.order == 1 ? 'asc' : 'desc'} />
                )}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};
DataTableHead.defaultProps = {
  style: {},
};

const TableCellPrint = (Dkey, key, index) => {
  let notDisplay = ['_id', 'action', 'edit', 'delete', 'recurringStatus'];
  let noImage = ['', null, undefined, 'none'];
  switch (key.type) {
    case 'tag': {
      return (
        <TableCell padding="none" align="center" style={key.style ? key.style : {}}>
          <span className={`label ${Dkey[key.id]}`}>
            {Status[Dkey[key.id]] ? Status[Dkey[key.id]] : Dkey[key.id] && Dkey[key.id].charAt(0).toUpperCase() + Dkey[key.id].slice(1)}
          </span>
          <span>
            {key.id === 'orderStatus' && (Dkey.orderStatus === 'cancelled' || Dkey.orderStatus === 'returned') && Dkey.undeliverableReason !== undefined && (
              <p style={{ backgroundColor: 'blue', color: '#fff', fontSize: '8px' }} className='my-2 p-1 rounded text-center'>
                {Dkey.undeliverableReason === undefined ? '' : convertUberUndeliverableReasonMessage(Dkey.undeliverableReason)}
              </p>
            )}
          </span>
        </TableCell>
      );
      break;
    }
    case "isDeliverToPharmacy": {
      return (
        <TableCell padding="none" align="left" style={key.style ? key.style : {}}>
          <span className={`label false`}>
            {Dkey[key.id] ? 'Pickup' : 'Delivery'}
          </span>
        </TableCell>
      );
    }
    case 'action': {
      return (
        <TableCell padding="none" align="left" style={key.style ? key.style : {}}>
          {key.action(Dkey)}
        </TableCell>
      );
      break;
    }
    case 'shipmentCompany': {
      return (
        <TableCell padding="none" align="center" style={key.style ? key.style : {}}>
          <img
            src={Dkey.storeOrderId && Dkey.storeOrderId.startsWith('se') ? purolatorIcon : uberIcon}
            alt="Vendor Logo"
            style={{ height: '24px', width: 'auto' }}
          />
        </TableCell>
      );
    }
    case 'driverName': {
      return (
        <TableCell padding="none" align="center" style={key.style ? key.style : {}}>
          <span className={`label false`}>
            {Dkey.storeOrderId && Dkey.storeOrderId.startsWith('se')
              ? (Dkey[key.id] || 'Purolator')
              : (Dkey[key.id] !== undefined ? Dkey[key.id] : 'N/A')}
          </span>
        </TableCell>
      );
    }

    case 'image': {
      return (
        <TableCell padding="none" key={index} align="left" style={key.style ? key.style : {}}>
          <div className="user-profile d-flex flex-row">
            <Avatar
              alt={Dkey['name']}
              src={!noImage.includes(Dkey[key.image]) ? Dkey[key.image] : require('assets/images/placeholder.jpg')}
              className="ml-3 user-avatar"
            />
            <div className="user-detail">
              <h5 className="user-name">{Dkey[key.id]} </h5>
            </div>
          </div>
        </TableCell>
      );
      break;
    }
    case 'date': {
      let format = key.date ? key.date : 'YYYY-MM-DD';

      const date = Dkey[key.id] ? moment(Dkey[key.id]).utc().format(format) : '--';

      const isFirstDateColumn = key.id === 'orderCreatedAt';

      return (
        <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
          {date}
          <div>
            {isFirstDateColumn && (
              <>
                <div style={{ marginBottom: '5px' }}>
                  {Dkey.recurrence &&
                    <span style={{ marginRight: '5px' }} className={`label `}>
                      {Dkey.recurrence}
                    </span>
                  }
                  {Dkey.recurringOrder ?
                    <span style={{ marginBottom: '5px', display: 'inline-block' }} className={`label pending`}>
                      Recurring Order
                    </span>
                    :
                    <>
                      {!Dkey.recurringOrder && Dkey.orderStatus === "recurring" &&
                        <span style={{ marginBottom: '5px', display: 'inline-block' }} className={`label cancelled`}>
                          Recurring Pause
                        </span>
                      }
                    </>
                  }

                  {Dkey.endedRecurringOrder && (
                    <div>
                      <span className={`label cancelled`}>Recurrence Ended</span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div>{Dkey.newRecurringOrder && isFirstDateColumn && <span className={`label assigned`}>Created from a Recurring Order</span>}</div>
        </TableCell>
      );

      break;
    }

    case 'currency': {
      return (
        <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
          {getPriceWithoutTax(roundToTwo(Dkey[key.id]))}
        </TableCell>
      );
    }

    case 'Orderlink': {
      return (
        <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
          <Link to={`/app/deliveries/all/${Dkey.orderId}`}>{Dkey[key.id]}</Link>
        </TableCell>
      );
    }

    case 'subtotal': {
      return (
        <TableCell padding="none" align="center" key={index} style={key.style ? key.style : {}}>
          {(Dkey[key.id]).toFixed(2)}
        </TableCell>
      );
    }

    case 'total': {
      return (
        <TableCell padding="none" align="center" key={index} style={key.style ? key.style : {}}>
          {(roundToTwo(Dkey[key.id] / 100)).toFixed(2)}
        </TableCell>
      );
    }


    case 'isBillPaid': {
      return (
        <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
          {Dkey[key.id]}
        </TableCell>
      );
    }



    default: {
      if (Array.isArray(Dkey[key.id]) || key.numeric) {
        return (
          <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
            {Dkey[key.id] && Dkey[key.id].length > 0 ? Dkey[key.id].length : 0}
          </TableCell>
        );
      } else {
        return (
          <TableCell padding="none" align="left" key={index} style={key.style ? key.style : {}}>
            {Dkey[key.id] || '--'}
          </TableCell>
        );
      }

      break;
    }
  }
};
class DataTableBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loading, page, deletePopupBtn, rowsPerPage, data, column, actions, actionBtn, popupBtn, editBtn, deleteBtn, recurringStatusBtn } = this.props;
    let columnNames = column
      .map((key) => {
        return key;
      })
      .filter((key) => key != '_id' && key != 'action');
    let notDisplay = ['_id', 'action', 'edit', 'delete', 'recurringStatus'];
    return (
      <TableBody>
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            return (
              <TableRow hover tabIndex={-1} key={Dkey._id}>
                <TableCell padding="none" align="left">
                  {++index + page * rowsPerPage}
                </TableCell>
                {column.map((key, index) => {
                  if (!notDisplay.includes(key.id)) {
                    return TableCellPrint(Dkey, key, index);
                  }
                })}
                {actions && (
                  <TableCell padding="none" align="left" key={index}>
                    <Action actions={actions} id={Dkey._id} />
                  </TableCell>
                )}

                {actionBtn && (
                  <TableCell padding="none" align="left" key={index}>
                    <NavLink to={`${actionBtn.link}${Dkey._id}`}>
                      <Button className="bg-amber text-white CBmargin">
                        <i className={`zmdi ${actionBtn.icon}`} /> {actionBtn.title}
                      </Button>
                    </NavLink>
                  </TableCell>
                )}
                {popupBtn && (
                  <TableCell padding="none" align="left" key={index}>
                    <Button className="bg-amber text-white CBmargin" onClick={(e) => popupBtn.action(true, Dkey._id, Dkey)}>
                      <i className={`${popupBtn.icon ? popupBtn.icon : 'zmdi zmdi-eye'}`} />
                    </Button>
                  </TableCell>
                )}
                {deletePopupBtn && (
                  <TableCell padding="none" align="left">
                    <Button className="bg-red text-white CBmargin" onClick={(e) => deletePopupBtn.action(true, Dkey._id, Dkey)}>
                      <i className={`${'zmdi zmdi-delete'}`} />
                    </Button>
                  </TableCell>
                )}
                {editBtn && (
                  <TableCell padding="none" align="left">
                    <Button className="bg-amber text-white CBmargin" onClick={(e) => editBtn.action(true, Dkey._id, Dkey)}>
                      <i className={`${editBtn.icon ? editBtn.icon : 'zmdi zmdi-edit'}`} />
                    </Button>
                  </TableCell>
                )}
                {recurringStatusBtn && (
                  <TableCell padding="none" align="left">
                    <Button
                      className={`${Dkey.recurringOrder ? 'bg-red' : 'bg-blue'} text-white CBmargin`}
                      onClick={(e) => recurringStatusBtn.action(true, Dkey._id, Dkey)}
                    >
                      <i className={`${Dkey.recurringOrder ? 'zmdi zmdi-stop' : 'zmdi zmdi-play'}`} />
                    </Button>
                  </TableCell>
                )}

                {deleteBtn && (
                  <TableCell padding="none" align="left">
                    <Button className="bg-red text-white CBmargin" onClick={(e) => deleteBtn.action(true, Dkey._id, Dkey)}>
                      <i className={`${deleteBtn.icon ? deleteBtn.icon : 'zmdi zmdi-delete'}`} />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}

        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              <Loading />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }
}

class DataTablePagination extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { count, page, rowsPerPage, rowsPerPageOptions, onChangePage } = this.props;
    return (
      <TableFooter>
        <TableRow>
          <TablePagination count={count} rowsPerPage={rowsPerPage} page={page} onChangePage={onChangePage} rowsPerPageOptions={rowsPerPageOptions} />
        </TableRow>
      </TableFooter>
    );
  }
}

class Action extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      anchorEl: undefined,
      menuState: false,
    };

    this.onOptionMenuSelect = this.onOptionMenuSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  onOptionMenuSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  render() {
    const { id, actions } = this.props;
    const { menuState, anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton key={id} onClick={(e) => this.onOptionMenuSelect(e)}>
          <i className="zmdi zmdi-more-vert" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={menuState}
          onClose={(e) => this.handleRequestClose()}
          MenuListProps={{
            style: {
              width: 93,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          {actions &&
            actions.map((key, index) => {
              if (key.action) {
                return (
                  <MenuItem key={key['label']} onClick={(e) => key.action(true, id)} className="customIcon">
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    View
                  </MenuItem>
                );
              } else if (key['label'] === 'Delete') {
                return (
                  <MenuItem key={key['label']} onClick={(e) => this.handleRequestClose()} className="customIcon">
                    <a href="javascript:void(0)" onClick={() => key.link(id)}>
                      <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </a>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem key={key['label']} onClick={(e) => this.handleRequestClose()} className="customIcon">
                    <NavLink to={`${key.link}/${id}`}>
                      <ListItemIcon>
                        {key['icon'] !== '' && key['icon']}
                        {key['label'] === 'View' && <VisibilityIcon fontSize="small" />}
                        {key['label'] === 'Edit' && <EditIcon fontSize="small" />}
                        {key['label'] === 'Pay' && <MonetizationOnIcon fontSize="small" />}
                      </ListItemIcon>
                      {key['label']}
                    </NavLink>
                  </MenuItem>
                );
              }
            })}
        </Menu>
      </Fragment>
    );
  }
}

const ActionLink = (props) => {
  const { index, actionBtn, id } = props;
  return (
    <TableCell padding="none" align="right" key={index}>
      <NavLink to={`${actionBtn.link}${id}`}>
        <Button className="bg-amber text-white CBmargin">
          <i className={`zmdi ${actionBtn.icon}`} /> {actionBtn.title}
        </Button>
      </NavLink>
    </TableCell>
  );
};
const ActionPopup = (props) => {
  const { key, action, id, title, icon } = props;
  return (
    <TableCell padding="none" align="right" key={key}>
      <Button className="bg-amber text-white CBmargin" onClick={() => action(id)}>
        <i className={`zmdi ${icon}`} /> {title}
      </Button>
    </TableCell>
  );
};
ActionPopup.defaultProps = {
  key: Math.random(),
  title: '',
  icon: 'Zmdi-delete',
};

// DataTable= withRouter(DataTable)
// DataTableHead= withRouter(DataTableHead)
// DataTableBody= withRouter(DataTableBody)
// DataTablePagination= withRouter(DataTablePagination)
export {
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  ActionLink,
  moment,
};

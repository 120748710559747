// Api BASE URLs
import Cookies from "js-cookie";

export const API_URL = "https://api.meds-on-wheels.com";
// export const API_URL = 'http://localhost:5004';
// export const API_URL = "http://3.96.24.120:5004";
export const API_VERSION = '/api/v1';

// COMMON CONSTANT
export const USER_TYPE = 'Pharmacy';
export const API_TOKEN = Cookies.get('user_id');
export const VENDOR_ID = Cookies.get('_id');
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const Decimals = /^[0-9]+(\.[0-9]+)?$/;
// max digit 13
export const US_NUMERIC_WITH_OR_WITHOUT_PLUS_AT_START = /^\+?1?[- ]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
export const ALPHABET = /^[A-Za-z -]+$/;
export const PASS_VALIDATION_MSG = 'password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.';

// AUTH URL
export const API_LOGIN = '/store/storelogin';

//PASSWORD
export const API_FORGOTPASSWORD = '/store/forgotpassword/';
export const API_RESETPASSWORD = '/store/resetpassword/';

//RECURRING
export const API_GET_RECURRING_ORDERS = '/store/getRecurringOrders/';
// export const API_DELETE_RECURRING_ORDERS = '/store/deleteRecurringOrders/';
export const API_EDIT_RECURRING_ORDER = '/store/editRecurringOrder/';
// export const API_Change_RECURRING_ORDERS = '/store/changeRecurringOrders/';
// DASHBOARD
export const DASHBOARD = '/store/getDashboardList';
export const API_UPADTE_STORE_STATUS = '/store/storeUpdateOnlineOfflineStatus';
// Stores
export const API_GET_STORES_URL = '/store/';
export const API_GET_STORES_BY_ID = '/store/getstorebyId/';
export const API_GET_PHARMACY_PATIENTS = '/store/getPharmacyPatients/';
export const API_DELETE_PHARMACY_PATIENTS = '/store/deletePharmacyPatients/';
export const API_SEARCH_PHARMACY_PATIENTS = '/store/searchPatients/';
export const API_GET_SUB_CATEGORIES = '/category/getCategoryBySubCat/';
export const API_ADD_STORES = '/store/register/';
export const API_EDIT_STORES = '/store/updatestore/';
export const API_UPLOAD_PATIENTS = '/store/uploadPatients/';
export const API_EDIT_PATIENT = '/store/editPatientForPharmacy/';
export const API_ADD_STORE_BANKAC = '/store/addaccount/';
export const API_ADD_STORE_DOCS = '/store/addStoreDoc/';
export const API_GET_STORE_DOCS = '/store/getStoreDoc/';
export const API_UPLOAD_STORE_ITEMS = '/store/uploadStoreItem/';
export const API_GET_UNITS = '/adminUnit/getEnabledUnitList';

// Store's Items
export const API_GET_STORES_ITEMS = '/store/getAllStoreItem/';
export const API_GET_STORES_ITEM_BY_ID = '/store/getItemById/';
export const API_ADD_STORES_ITEM = '/store/addStoreItem/';
export const API_EDIT_STORES_ITEM = '/store/updateStoreItem';
export const API_DELETE_STORES_ITEM = '/store/deleteStoreItem';
export const API_UPDATE_STORE_ITEM_STATUS = '/store/updateItemStatus';

// Drivers
export const API_GET_DRIVER_URL = '/store/getDriverList/';
export const API_GET_DRIVER = '/admin/getDriverById/';
export const API_ADD_DRIVER = '/admin/addDriver/';
export const API_EDIT_DRIVER = '/admin/updateDriver/';
export const API_DELETE_DRIVER = '/admin/deleteDriver/';

// Category
export const API_GET_CATEGORY_URL = '/category/';
export const API_GET_CATEGORY = '/category/edit/';
export const API_ADD_CATEGORY = '/category/add/';
export const API_EDIT_CATEGORY = '/category/update/';
export const API_DELETE_CATEGORY = '/category/remove/';
export const API_GET_SUB_STORES_CATEGORIES = '/category/getCategoryBySubCat/';
export const API_GET_STORE_CATEGORY_URL = '/storecategory/';

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = '/adminSetting/getPromoCodeList/';
export const API_ADD_PROMOCODE = '/adminSetting/addPromoCode/';
export const API_EDIT_PROMOCODE = '/adminSetting/editPromocode/';
export const API_UPDATE_PROMOCODE_STATUS = '/adminSetting/updatePromocodeStatus/';
export const API_GET_PROMOCODE = '/adminSetting/getPromocodeDetails/';
export const API_DELETE_PROMOCODE = '/adminSetting/promocodeDelete/';
export const API_RESTURANT_TRANSATION = '/store/pharmacypaymentshistory';

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = 'admin/sendNotification';

// Settings
export const API_UPDATE_ADMIN_PASSWORD = '/store/changePassword/';
export const API_GET_BASIC_SETTINGS = '/setting/getBasicSettingInfo';

//orders

export const API_GET_ORDERS = '/store/storeOrdersWithFilter/';
export const API_ADD_DRAFT_ORDERS = '/admin/order/addDraftOrder';
export const API_GET_ORDER_DETAILS = '/pharmacy/orderdetails/';

export const API_GET_ORDER_BY_ID = '/store/orderdetails/';
export const API_GET_PHARMACY_DETAILS = '/pharmacy/profile/';
export const API_GET_ORDER_ACCEPT = '/pharmacy/acceptorder/';
export const API_GET_ORDER_REJECT = '/pharmacy/rejectorder/';
export const API_GET_ORDER_MARK = '/store/markorderready/';
export const API_GET_ORDER_COMPLETE = '/pharmacy/markOrderstatusUpdate/';
export const API_ASSIGN_DRIVER = '/pharmacy/assignOrderSelectDriver';
export const API_GET_DELIVERY_CHARGES = '/pharmacy/getDeliveryCharges';
export const API_SUBMIT_ORDERS = '/pharmacy/acceptCharges';
export const CANCEL_ORDER = '/pharmacy/cancelOrder';
export const API_GET_ADMIN_BANNER = '/admin/bannerImage';
export const API_UPDATE_ORDER_INVOICE_STATUS = '/admin/updateOrderInvoiceStatus'
export const ERROR_COUNT_API = '/pharmacy/counterror';

export const API_GET_PUROLATOR_CHARGES = '/purolator/shipengine-calculate-expenses';
export const API_CREATE_PUROLATOR_LABEL = '/purolator/create-purolator-delivery';
export const API_TRACK_PUROLATOR_SHIPMENT = '/purolator/track-shipment';
export const API_CREATE_PUROLATOR_SHIPMENT = '/purolator/create-shipment';
export const API_GET_PUROLATOR_CREATE_PICKUP = '/purolator/create-pickup';
export const API_VALIDATE_PUROLATOR_ADDRESS = '/purolator/validate-shipengine-address';
export const API_CANCEL_PUROLATOR_SHIPMENT = '/purolator/cancel-shipment';
export const SUBSCRIBE_WEBHOOK_PUROLATOR = '/purolator/subscribe-to-webhook';
export const API_TODAYS_SCHEDULE_ORDERS = '/purolator/checkTodayOrder';


//create orders
export const API_GET_ALL_NEAR_DRIVERS = '/admin/order/getNearByDrivers/';
export const API_GET_ALL_CUSTOMERS = '/user/';
export const API_ADD_INSTANT_ORDER = '/admin/order/addOrderFromPharmacy/';
export const API_ADD_SCHEDULE_ORDER = '/admin/order/addScheduleOrderFromPharmacy/';
export const API_GET_ALL_ORDERS = '/admin/order/storeOrdersWithFilter/';
export const API_CALCULATE_ORDER_DELIVERY = '/admin/order/deliveryCharges/';
export const API_GET_DRIVERS_FOR_SCHEDULE_ORDER = '/admin/driver/getDriversForScheduleOrder/';

export const API_PHARMACY_STRIPE_CONNECT = API_URL + API_VERSION + '/pharmacy/stripe/connect?id=';
export const API_DRIVER_STRIPE_CONNECT = API_URL + API_VERSION + '/driver/stripe/connect?id=';

///NOTIFCATION API

export const API_GET_NOTIFICATION_ITEMS = '/pharmacy/notificationList/';



// PAYMENT API
export const API_ADD_PAYMENT = "/paymentdotnet";
export const API_ADD_CUSTOMER_PROFILE = "/paymentdotnet/createCustomerProfile/";
export const API_GET_PAYMENT = "/paymentdotnet/customer/";


//CUSTOMER PROFILE API
export const API_GET_CUSTOMER_PROFILE = "/customerProfile/";
export const API_UPDATE_CUSTOMER_PROFILE = "/customerProfile/";
